.RecommendComponent {
  width: 100%;
  margin: 30px auto;
  .swiper-slide{
	width: 170px!important;
	margin: 0 60px 0 5px!important;
  }
  .user {
	width: 190px;
	height: 348px;
	display: inline-flex;
	flex-flow: column;
	align-items: center;

	.header-img {
	  width: 156px;
	  height: 156px;
	  border-radius: 100%;
	  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	  margin-bottom: 20px;
	  object-fit: cover;
	}

	.name_gender {
	  margin-bottom: 10px;
	  display: flex;

	  .username {
		font-size: 30px;
		font-weight: bold;
		line-height: 42px;
		color: #2E2E2E;
		max-width: 146px;
	  }

	  .gender {
		width: 32px;
		height: 32px;
		margin-left: 4px;
	  }
	}

	.age_city {
	  font-size: 28px;
	  font-weight: 400;
	  line-height: 30px;
	  color: #9F9F9F;
	  display: flex;
	  padding-bottom: 6px;

	  .age {
		margin-right: 6px;
	  }

	  .city {
		text-align: right;
		padding-left: 6px;
		height: 30px;
		border-left: 2px solid #9F9F9F;
		max-width: 120px;
	  }
	}

	.school {
	  font-size: 28px;
	  font-weight: 400;
	  line-height: 40px;
	  color: #9F9F9F;
	  text-align: center;
	  width: 100%;
	}

	.ellipsis-line {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
  }
}
