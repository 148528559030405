.member_box {
  margin: 0 auto;
  width: 750px;
  overflow: hidden;
  position: relative;
  .wrap {
    width: 720px;
    margin: 0 auto;
    margin-top: 30px;
		.user-info {
      height:180px;
      background: url(../../images/vip.png) no-repeat;
      background-size: contain;
			display: flex;
      align-items: center;
      border-radius: 5px;
      .userimg{
        margin-left: 40px; 
        margin-right: 30px;
        margin-top: 20px;
        img {
          width: 120px;
          height: 120px;
          border-radius: 100%;
        }
      }
      .nickname {
        height: 32px;
        font-size: 28px;
        //font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #EEDEA2;
        line-height: 32px;
        img {
          width: 32px;
          height: 32px;
          margin-left: 10px;
          vertical-align: middle;
        }
      }
      .date {
        height: 26px;
        font-size: 26px;
        //font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 26px;
        margin-top: 22px;
        span{
          color: #505050;
          margin-left: 10px;
        }
      }

			
		}

    .bg {
      
      img {
        width: 750px;
        height: 160px;
      }
    }
	}
  

	.member_detail {
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 30px;
    width:720px;
    padding: 40px 30px;
    .title{
      font-size: 34px;
      font-weight: bold;
      color: #131313;
      line-height: 34px;
      //font-family: PingFangSC-Semibold, PingFang SC;
    }
		.member-item {
      margin-top: 50px;
			.member-item-title {
        height: 33px;
        font-size: 28px;
        //font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: bold;
        color: #131313;
        line-height: 28px;
        span {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
          width: 10px;
          height: 10px;
          background: linear-gradient(145deg, #292828 0%, #453025 100%);
          border-radius: 5px;
        }
			}

			// .member-item-title::before {
			// 	content: '';
			// 	width: 100px;
      //   height: 100px;
      //   background: linear-gradient(145deg, #292828 0%, #453025 100%);
      //   border-radius: 5px;
			// }

			.member-item-info {
				margin-top: 10px;
				font-size: 26px;
        font-weight: 400;
        color: #505050;
        line-height: 39px;
        //font-family: PingFangSC-Regular, PingFang SC;
        margin-left: 20px;
			}
		}

	}

	.member_type_wrap {
    margin: 0 auto;
		margin-top: 40px;
    width: 720px;
		.member-type-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
      margin-top: 30px;

			.member-type {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: space-between;
				padding: 40px 0 26px 0;
				box-sizing: border-box;
				width: 32%;
				height: 220px;
				background: #FFFFFF;
				border: 2px solid #EBECEF;
				border-radius: 10px;
				position: relative;

				.title {
          height: 28px;
          font-size: 28px;
          //font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #131313;
          line-height: 28px;
				}

				.price {
          height: 30px;
          //font-family: Couture-Bold, Couture;
          color: #E35450;
          line-height: 30px;
				}
				.price-num {
          font-weight: bold;
          font-size: 38px;
          
				}
        .price-unit {
          font-size: 34px;

        }

				.desc {
          height: 28px;
          font-size: 28px;
          //font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #BBBBBB;
          line-height: 28px;
				}
			}

			.member-type.active {
				background: #FFF4F4;
				border-color: #EF5050;
			}

			.member-type.recommend::before {
				position: absolute;
				content: '推荐';
				// opacity: 1;
				font-size: 24px;
        //font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				line-height: 36px;
				text-align: center;
				color: #FFFFFF;
				top: -18px;
				left: -2px;
        width: 75px;
        height: 36px;
        background: linear-gradient(180deg, #E35450 0%, #DF3D38 100%);
        border-radius: 14px 2px 14px 2px;
			}
		}
	}

	.footer_new {
    text-align: center;
    margin: 50px 0;
		.member_btn {
      width: 420px;
      height: 90px;
      line-height: 90px;
      background: linear-gradient(180deg, #E35450 0%, #DF3D38 100%);
      box-shadow: 0px 10px 10px 0px rgba(227, 84, 80, 0.2);
      border-radius: 45px;
      text-align: center;
      font-size: 30px;
      //font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      
		}

	}
}
