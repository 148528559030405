.CouponDetail {
	display: flex !important;
	flex-flow: column;
	justify-content: space-between;
	background: #ffccd1;
	.am-list-item.am-input-error .am-input-control input {
		color: #EF5052;
	}
	.matchmaker-content {
		overflow-x: hidden;
		background: #fff9e7;
		padding: 0 !important;
		margin: 60px 30px;
		width: calc(100% - 60px);
		overflow: visible;

		.info-title {
			font-size: 36px;
			font-weight: bold;
			line-height: 50px;
			color: #000000;
			text-align: center;
			position: relative;
			display: flex;
			justify-content: center;
			z-index: 1;

			span {
				position: relative;
				z-index: 1;
			}
		}

		.info-title:after {
			content: "";
			position: absolute;
			height: 13px;
			width: 173px;
			background: #ef5050;
			border-radius: 50%;
			bottom: 0;
			z-index: 0;
		}

		.bg {
			width: 101%;
			margin-bottom: 20px;
			position: relative;
			margin-left: -16px;
		}

		.details {
			border: 2px solid #000;
			box-sizing: border-box;
			padding: 0 0 30px 0 !important;
			width: calc(100% - 40px);
			margin: 40px 20px;
			border-radius: 12px;
			position: relative;
			top: -170px;
			background: #fff;
			overflow: visible;

			.girl {
				width: 181px;
				position: relative;
				margin-top: -96px;
				margin-left: -36px;
			}

			.tips {
				text-indent: 58px;
				margin: 0 20px;
				line-height: 50px;

				.flower {
					height: 77px;
					position: absolute;
					right: -20px;
				}
			}

			.tips.laster {
				width: calc(100% - 40px);
				background-image: linear-gradient(to right,
						#ef5050 0%,
						#ef5050 50%,
						transparent 50%);
				background-size: 16px 14px;
				background-repeat: repeat-x;
				background-position: bottom;
				padding-bottom: 60px;
			}

			.title {
				height: 70px;
				line-height: 70px;
				font-size: 34px;
				font-weight: bold;
				background: #ffc619;
				border: 1px solid #000;
				display: inline-block;
				padding: 0 50px 0 20px;
				color: #000;
				margin: 40px 0;
				position: relative;

				.hand {
					position: absolute;
					transform: rotateY(180deg);
					width: 75px;
					bottom: -40px;
					right: -60px;
				}
			}

			.star {
				text-indent: 0;
				display: flex;
				position: relative;
				margin-top: 20px;

				.red-color {
					color: #ef5050;
				}

				>img {
					width: 26px;
					margin-right: 20px;
					height: 26px;
					margin-top: 10px;
				}

				.icon {
					position: absolute;
					right: -48px;
					bottom: 40px;
					transform: rotate(120deg);
					display: inline-flex;

					.sanjiao1 {
						width: 38px;
						height: 39px;
						margin-right: 10px;
					}

					.sanjiao2 {
						width: 28px;
						height: 32px;
					}
				}

				.index {
					font-weight: bolder;
					font-size: 72px;
					margin-top: 10px;
					margin-right: 10px;
				}

				.index-title {
					display: flex;
					flex-flow: column;
					font-weight: 500;
					letter-spacing: 4px;
					margin-bottom: 20px;

					.dots {
						display: inline-flex;
					}

					img {
						width: 57px;
						height: 15px;
						margin-right: 6px;
					}
				}

				.box-img {
					width: 76px;
					position: absolute;
					right: -30px;
					bottom: 8px;
				}

				.flower.index {
					left: -40px;
					transform: rotateY(-180deg);
					right: auto;
					top: 40px;
				}
			}
		}

		.details.qrcode {
			position: relative;
			box-sizing: border-box;

			.box-lines {
				position: absolute;
				width: 100%;
				top: -62px;
				left: 0;
				display: inline-flex;
				justify-content: space-around;

				div {
					height: 80px;
					width: 18px;
					background: #ffc619;
					border: 1px solid #000;
				}
			}

			.qrcode-box {
				display: flex;
				flex-flow: column;
				align-items: center;

				img {
					width: 224px;
					height: 224px;
					margin-top: 37px;
					margin-bottom: 16px;
				}

				div {
					font-size: 28px;
					font-weight: 400;
					line-height: 40px;
					color: #7c7c7c;
				}
			}
		}

		.details.price-container {
			border: 0;
			background: transparent;
			padding: 30px 0 !important;

			.price-box {
				display: flex;
				justify-content: space-between;
				padding: 0 30px;

				.info-item {
					display: flex;
					flex-flow: column;
					align-items: center;
					height: 148px;
					flex: 1;
					background: #ffbaba;
					border: 1px solid #000;
					border-radius: 12px;
					box-sizing: border-box;
					font-size: 28px;
					font-weight: 400;
					line-height: 40px;
					color: #000;

					.price {
						font-size: 58px;
						font-weight: 500;
						line-height: 66px;
						color: #000;
					}

					.price-activity {
						font-size: 36px;
						font-weight: 500;
						line-height: 66px;
						color: #000;
					}
				}

				.info-item.active {
					background: #ef5050;
					color: #fff;

					.price {
						font-size: 58px;
						font-weight: 500;
						line-height: 66px;
						color: #fff;
					}

					.price-activity {
						font-size: 36px;
						font-weight: 500;
						line-height: 66px;
						color: #fff;
					}
				}

				.info-item.left {
					margin-right: 20px;
				}

				.discount {
					position: relative;

					p {
						position: absolute;
						left: -2px;
						top: -24px;
						background-image: url("../../images/discount.png");
						width: 120px;
						height: 48px;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						display: flex;
						padding-left: 15px;
						align-items: center;
					}
				}
			}
		}
	}

	.footer {
		position: fixed;
		display: flex;
		bottom: 0;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 80px;
		height: 120px;
		padding: 0 30px;
		background: #fff;
		box-shadow: 0 0 10px #e3e3e3;

		.home-btn {
			padding-left: 0;
		}

		.footer-home-img {
			width: 83px;
			height: 85px;
			position: absolute;
			left: 0;
		}

		.matchmaker-btn {
			background-image: url("../../images/matchmaker/btn.png");
			background-color: #fff;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100% 101%;
			width: 547px;
			height: 100px;
			border-radius: 85px;
		}
	}
}

.am-modal-wrap {
	.contactInformation {
		.am-list-body {
			.am-list-item {
				.am-list-line::after {
					height: 0px;
				}

				.am-input-item {
					height: 56px;
					padding: 0;
				}
			}

			.am-input-label {
				width: 100px;
				color: #515a6e;
			}

			.am-input-control {
				border: 1px solid #dcdee2;
				border-radius: 10px;
				padding-left: 10px;

				input {
					font-size: 30px;
					padding: 10px;
				}
			}

			.am-list-content {
				text-align: center;

				button {
					width: 300px;
					margin-top: 30px;
					border-radius: 20px;
					background: #EF5050;
				}
			}
		}

		.am-list-body::before {
			height: 0px;
		}

		.am-list-body::after {
			height: 0px;
		}
	}

	.title {
		font-size: 30px;
		font-weight: 500;
		color: #131313;
		line-height: 30px;
	}

	.order {
		font-size: 30px;
		font-weight: 500;
		color: #131313;
		line-height: 30px;
		margin-top: 30px;
	}

	.name {
		color: #e35450;
		font-size: 30px;
		margin-top: 30px;
	}

	.qrcode {
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			width: 320px;
			height: 320px;
		}

		.text {
			color: #131313;
			font-size: 30px;
		}

		.smallText {
			color: #666666;
			font-size: 26px;
			margin-top: 20px;
		}
	}

	.yy {
		margin: 20px auto;
		width: 250px;
		height: 60px;
		line-height: 60px;
		background: linear-gradient(180deg, #E35450 0%, #DF3D38 100%);
		box-shadow: 0px 10px 10px 0px rgba(227, 84, 80, 0.2);
		border-radius: 45px;
		color: #fff;
	}
}
