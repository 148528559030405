.mini__question_box {
	width: 100%;
	height: 100%;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	.question_box_finished_img {
		width: 407px;
		height: 259px;
		margin-top: 157px;
	}
	.question_box_finished_title {
		margin-top: 80px;
		color: #222;
		font-size: 40px;
		font-weight: bold;
		width: 100%;
	}
	.question_box_finished_content {
		font-size: 32px;
		color: #707070;
		margin-top: 25px;
	}
	.question_box_title {
		width: 100%;
		color: #222222;
		font-size: 40px;
		margin-top: 93px;
		font-weight: bold;
	}

	.question_box_content {
		width: 100%;
		color: #707070;
		font-size: 32px;
		margin-top: 25px;
		span {
			color: #DB3C3C;
		}
	}

	img {
		width: 344px;
		height: 322px;
		margin-top: 116px;
	}

	.question_box_button {
		margin-top: 127px;
		width: 443px;
		height: 75px;
		background: #EF5050 !important;
		border-radius: 38px;
		color: #FFFFFF;
		font-size: 30px;
		text-transform: none;
	}
	.give__up {
		margin-top: 22px;
		font-size: 30px;
		color: #9F9F9F;
	}
}
