.Member {
	padding-bottom: 50px;

	.top-card-wrap {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 480px;
		position: relative;

		.user-info {
			display: flex;
			flex-flow: column;
			align-items: center;
			position: absolute;
			top: 60px;

			img {
				width: 116px;
				height: 116px;
				border-radius: 100%;
			}

			div {
				height: 42px;
				font-size: 30px;
				font-weight: 500;
				line-height: 42px;
				color: #FFFFFF;
			}
		}

		.bg {
			height: 100%;
			width: 100%;
			background: radial-gradient(circle, #2F2C22 0%, #1A1A1D 100%);
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				margin-bottom: -78px;
				width: 450px;
				height: 429px;
			}
		}

		.card {
			width: 622px;
			height: 244px;
			position: absolute;
			bottom: 0;
			background: rosybrown;
			border-radius: 10px;
		}

		.bottom {
			position: absolute;
			width: 100%;
			height: 120px;
			bottom: 0;
		}
	}

	.page-title {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;

		.page-title-wrap {
			width: 266px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			img {
				width: 52px;
				height: 34px;
			}

			img:nth-last-of-type(1) {
				transform: rotateY(180deg);
			}

			div {
				position: relative;
				height: 50px;
				font-size: 36px;
				font-weight: 500;
				line-height: 50px;
				color: #404040;
			}
		}
	}

	.member-detail {
		border-bottom: 12px solid #f4f4f4;

		.member-item {
			padding: 26px 0;

			.member-item-title {
				height: 45px;
				font-size: 32px;
				font-weight: 500;
				line-height: 45px;
				color: #555555;
				position: relative;
				display: flex;
				align-items: center;
			}

			.member-item-title::before {
				content: '';
				width: 8px;
				height: 28px;
				background: #D9B672;
				border-radius: 4px;
				margin-right: 8px;
				margin-top: -4px;
			}

			.member-item-info {
				margin-top: 8px;
				font-size: 32px;
				font-weight: 400;
				line-height: 42px;
				color: #848484;
			}
		}

	}

	.member-type-wrap {
		display: flex;
		flex-flow: column;
		align-items: center;
		margin-bottom: 100px;
		margin-top: 40px;

		.member-type-box {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.member-type {
				display: flex;
				flex-flow: column;
				align-items: center;
				justify-content: space-between;
				padding: 24px 0;
				box-sizing: border-box;
				width: 32%;
				height: 249px;
				background: #FFFFFF;
				border: 1px solid #C1C1C1;
				border-radius: 8px;
				position: relative;

				.title {
					height: 42px;
					font-size: 30px;
					font-weight: 400;
					line-height: 64px;
					color: #0B0B0B;
				}

				.price {
					height: 45px;
					font-size: 32px;
					font-weight: 400;
					line-height: 57px;
					color: #FD3C3B;
					margin-top: -50px;
				}

				.price-num {
					font-size: 59px;
					height: 83px;
					font-weight: 600;
					line-height: 105px;
					color: #FD3C3B;
				}

				.desc {
					height: 42px;
					font-size: 30px;
					font-weight: 400;
					line-height: 42px;
					color: #8D8D8D;
				}
			}

			.member-type.active {
				background: #FFF4F4;
				border-color: #EF5050;

				.title {
					color: #FD3C3B;
				}
			}

			.member-type.recommend::before {
				position: absolute;
				content: '推荐';
				width: 93px;
				height: 46px;
				background: linear-gradient(45deg, #F8873E 0%, #FB5E3C 47%, #FE2F39 100%);
				opacity: 1;
				border-radius: 10px 0px 10px 0px;
				font-size: 30px;
				font-weight: 400;
				line-height: 46px;
				text-align: center;
				color: #FFFFFF;
				top: -18px;
				left: -2px;
			}
		}
	}

	.footer {
		position: fixed;
		display: flex;
		bottom: 0;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-top: 80px;
		height: 110px;
		padding: 0 20px;
		background: #fff;
		box-shadow: 0 0 10px #e3e3e3;
		.home__box {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-size: 20px;
			img {
				margin-bottom: 5px;
			}
		}
		.home-btn {
			padding-left: 0;
		}
		.footer-home-img {
			width: 43px;
			height: 41px;
		}
		.member-btn {
			padding: 0;
			width: 680px;
			height: 75px;
			background: linear-gradient(90deg, #F5D990 0%, #C19859 100%);
			border-radius: 38px;
			font-size: 30px;
			font-weight: 400;
			color: #FFFFFF;
		}

	}
}
