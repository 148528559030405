.custom_price {
    height: 100vh;
    width: 100vw;
    .top-card-wrap {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 100%;
		position: relative;
        .bg {
			height: 100%;
			width: 100%;
			// background-repeat: no-repeat;
            // background-size: cover;
            background-size: 100% 100%;
            // background-position: center;
            background-position: center; 
            background-repeat: no-repeat;  
            background-image: url('../images/background.png');
			
		}
    }
    .details {
        width: 100%;
        position: absolute;
        // top: 42%;
        height: 50%;
        bottom: 50px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        // top: 500px;
        // height: calc(100% - 500px);
        .qrcode-box {
            display: flex;
            flex-flow: column;
            align-items: center;
            margin-top: -15px;
            img {
                width: 140px;
                height: 140px;
                // margin-top: 37px;
                margin-bottom: 24px;
            }

            div {
                font-size: 28px;
                font-weight: 500;
                line-height: 40px;
                color: #E9BF8C;
            }
        }
        .activityPrice {
            // width: 600px;
            width: 90%;
            height: 146px;
            margin: 22px auto 42px auto;
            display: flex;
            justify-content: space-evenly;
            .priceContainer {
                width: 38%;
                // width: 280px;
                height: 150px;
                border: 4px solid #FFFFFF;
                background: linear-gradient(132deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.04) 35%, rgba(255, 255, 255, 0.18) 51%, rgba(255, 255, 255, 0) 71%, rgba(255, 255, 255, 0) 100%);
                opacity: 1;
                position: relative;
                .activityIcon {
                    width: 139px;
                    height: 77px;
                    position: absolute;
                    right: -55px;
                    top: -55px;
                    z-index: 1000;
                }
                .price {
                    width: 100%;
                    height: 95px;
                    line-height: 95px;
                    font-size: 54px;
                    font-weight: 400;
                    color: #FFFFFF;
                    opacity: 0.78;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .symbol {
                        font-size: 30px;
                        font-weight: 400;
                        line-height: 42px;
                        color: #FFFFFF;
                        opacity: 0.78;
                    }
                }
                .content {
                    width: 100%;
                    height: 47px;
                    background: linear-gradient(48deg, #E8E8E8 0%, #F5F5F5 39%, #FFFFFF 53%, #F2F2F2 64%, #E8E8E8 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #050302;
                    font-weight: 400;
                    font-size: 28px;
                    position: absolute;
                    bottom: 0;
                }
            }
            .active {
                border: 4px solid #8B7353;
                .content {
                    background: linear-gradient(48deg, #E8B473 0%, #FFEED5 53%, #E8B473 100%);
                }
            }
        }
        .footer {
            width: 80%;
            // width: 600px;
            height: 80px;
            background: linear-gradient(61deg, #E8B473 0%, #FFEED5 53%, #E8B473 100%);
            border-radius: 45px;
            margin: 30px auto 0 auto;
            display: flex;
            align-items: center;
            .bug {
                margin-left: 154px;
                font-size: 32px;
                font-weight: 400;
                color: #000000;
            }
            .discount {
                width: calc(100% - 300px);
                text-align: right;
                font-size: 22px;
                font-weight: 400;
                color: #000000;
                padding-right: 15px;
            }
        }
        .active {
            justify-content: center;
            .bug {
                margin-left: 0px;
                font-size: 32px;
                font-weight: 400;
                color: #000000;
            }
        }
    }
    
}
.price_through {
    margin-top: 18px;
    text-decoration: line-through;
    color: #fff;
    text-align: center;
    font-size: 40px;
    text-decoration-color: #F2F2F2;
    padding: 0 10px;
}