.privacyAgreement {
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	background: #ffffff;
	.name {
		text-align: center;
	}
	.content {
		text-indent: 2em;
		margin-top: 20px;
	}
	.title {
		margin-top: 20px;
	}
	.child {
		text-indent: 1em;
	}
	.conspicuous {
		color: #ff0000;
		font-weight: 600;
		// background-color: #ffff00
	}
	.underline-link {
		text-decoration-line: underline;
		cursor:pointer;
		color: #2d54a0;
	}
}
