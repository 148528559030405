.follow-up {
	height: 100%;
	display: flex !important;
	flex-flow: column;
	justify-content: space-between;
	background: #fff;
	font-size: 30px;
	.avatar {
		width: 156px;
		height: 156px;
		border-radius: 100%;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
		object-fit: cover;
	}
	.join_box_recommend {
		text-align: center;
		.join_recommend {
			height: 73px;
			width: 44px;
		}
	}
	.toast {
		z-index: 99999999;
		.MuiSnackbar-anchorOriginBottomCenter {
			bottom: 8px;
			height: 100%;
		}
	}
	.ellipsis-line {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.wrap {
		flex: 1;

		.container {
			padding: 20px;
			position: relative;
			.basic-info {
				padding: 0 24px;
				width: calc(100% - 10px);
				position: relative;
				background: #fff;
				margin-top: 30px;
				line-height: 3rem;

				.name-gender {
					display: flex;
					align-items: center;
					margin-left: 17px;
					margin-top: 60px;

					.name {
						font-size: 34px;
						font-weight: bold;
						line-height: 48px;
						color: #000000;
						margin-right: 20px;
					}

					.gender {
						width: 32px;
						height: 32px;
						border-radius: 50%;
					}
				}
				.info-item {
					display: flex;
					align-items: center;
					font-weight: 400;
					padding: 10px 26px;
					.info-item-value, .info-item-value-7 {
						word-break: break-word;
					}
				}
			}

		}
	}
	
	.footer {
		display: flex;
		height: 112px;
		min-height: 112px;
		padding: 0 20px;
		align-items: center;
		width: 100%;
		margin-top: 30px;
		.MuiGrid-spacing-xs-3 > .MuiGrid-item {
			padding: 12px;
		}
		.footer-right {
			display: flex;
			.btn {
				flex: 1;
				height: 75px;
				//line-height: 75px;
				background: #ef5050;
				text-align: center;
				padding: 0;
				border-radius: 38px;
				font-size: 30px;
				font-weight: 400;
				color: #ffffff;
				text-transform: lowercase;
				&.Mui-disabled {
					opacity: .5;
				}
				&~.btn {
					margin-left: 16px;
				}
				.btn-name {
					max-width: 180px;
				}
			}
			.matchmaker {
				background: #f09400;
				margin-left: 0;
			}
		}
	}

	.info-title {
		font-size: 36px;
		font-weight: bold;
		line-height: 50px;
		color: #000000;
		text-align: center;
		position: relative;
		display: flex;
		justify-content: center;
		z-index: 1;
		span {
			position: relative;
			z-index: 1;
		}
	}

	.info-title:after {
		content: "";
		position: absolute;
		height: 13px;
		width: 173px;
		background: #ef5050;
		border-radius: 50%;
		bottom: 0;
		z-index: 0;
	}
}
.jc-center {
	justify-content: center;
}

.fu-title {
	font-weight: 400;
	color: #6f6f6f;
}
