.ProvinceLinkage {
  	position: absolute;
  	top: 340px;
  	width: 100%;
  	height: 0;
  	z-index:9999;
	.ellipsis-line {
	  overflow: hidden;
	  text-overflow: ellipsis;
	  white-space: nowrap;
	}
	.p-top{
	  display: flex;
	  align-items: center;
	  justify-content: space-around;
	  height: 87px;
	  border-bottom: 1px solid #e3e3e3;
	  padding: 0 30px;
	  background: #fff;
	  .tap{
		display: flex;
		align-items: center;
		font-size: 28px;
		color: #383838;
		width: 200px;
		.current-name{
		  display: inline-block;
		}
		.tap-icon{
		  transition: all 0.3s;
		  transform: rotate(180deg);
		  font-size: 40px;
		  color: #797979;
		}
	  }
	}
  .container-box{
	position: relative;
	width: 100%;
	height: calc(100% - 87px);
	z-index:99999;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	transition: all .3s;
	.shadow-container{
	  position: relative;
	  width: 100%;
	  height: 100%;
	  background: rgba(0,0,0,.4);
	}
	.grid-box{
	  top: 0;
	  box-sizing: border-box;
	  margin: 0 auto;
	  width: 100%;
	  height: 80%;
	  display: flex;
	  position: absolute;
	  background: #fff;
	  .grid-column{
		position: relative;
		box-sizing: border-box;
		height:100%;
		flex: 1;
		.list-box{
		  height: 100%;
		  overflow-y: scroll;
		  padding: 0;
		  >ul{
			padding: 0;
		  }
		  .city-li{
			font-size: 32px;
			color: #2E2E2E;
			padding: 0;
		  }
		  .city-li.active{
			color:#F22B43;
		  }
		  .country-li{
			text-align: center;
			display: block;
			line-height: 100px;
		  }
		  .city-li-flex{
			display: flex;
			flex-flow: column;
			>div, >ul{
			  width: 100%;
			}
			>div{
			  line-height: 30px;
			}
		  }
		  .city-li.city{
			height: 100px;
			line-height: 36px;
		  }
		}
	  }
	  .grid-column.grid-country{
		width: 230px;
		background: #F8F8F8;
	  }
	  .grid-tag.grid-tag{
		width: 30px!important;
		height: 100%;
		display: flex;
		align-items: center;
		flex: 0;
		margin-right: 30px;
		z-index:99999;
		ul{
		  height: 98%;
		  width: 30px!important;
		  border-radius: 20px;
		  background: #F0F0F0;
		  display: flex;
		  flex-flow: column;
		  justify-content: space-between;
		  align-items: center;
		  padding: 20px 0;
		  li{
			padding: 0!important;
			font-size:20px!important;
			line-height: unset!important;
			a{
			  text-decoration: none;
			  color: #6C6C6C;
			  width: 100%;
			  text-align: center;
			}
		  }
		}
	  }
	}
  }
  .container-box.active {
	opacity: 1;
	pointer-events: auto;
  }
}
.ProvinceLinkage.active{
  height: calc(100% - 96px);
}
