.ThirdPartyList {
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	background: #ffffff;
	.third-table{
		width:100%;
		border-collapse: collapse;
		.tableHeader{
			font-weight: 600;
			border:2px solid black;
			word-break: break-all;
		}
		.tableCell{
			border:2px solid black;
			text-align: center;
			word-break: break-all;
		}
		.type-style{
			min-width: 100px;
		}
		.mode-style{
			min-width: 80px;
		}
	}
}