.recharge {
	flex: 1;
	overflow-y: scroll;
	padding: 0;
	background: #f8f8f8;
	height: 100vh;
	padding-bottom: 72px;
	.recharge-header {
		display: flex;
		flex-flow: column;
		align-items: center;
		justify-content: center;
		margin: 30px 34px 50px 34px;
		height: 340px;
		background: url(../../images/rechange-card.png) no-repeat;
		background-size: contain;
		img {
			width: 130px;
			height: 130px;
			border-radius: 100%;
			margin-bottom: 6px;
		}

		div {
			font-size: 28px;
			font-weight: 600;
			line-height: 28px;
			color: #ffffff;
		}
		.balance {
			font-size: 28px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #ffffff;
			line-height: 28px;
			position: relative;
			margin-left: 20px;
			margin-top: 40px;
			.balance_coin {
				margin-left: 20px;
				font-size: 38px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #ffffff;
				line-height: 38px;
			}
		}

		.balance::after {
			content: "";
			position: absolute;
			width: 26px;
			height: 26px;
			background: url(../../images/icon.png) no-repeat;
			background-size: contain;
			top: 6px;
			margin-left: 10px;
		}
	}
	.selected {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		div.s-box {
			height: 190px;
			border: 3px solid #fff;
			border-radius: 20px;
			display: flex;
			flex-flow: column;
			align-items: center;
			margin-bottom: 30px;
			position: relative;
			background: #fff;
			flex: 0 1 196px;
			.money {
				font-size: 28px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #6e6e6e;
				line-height: 28px;
				margin-top: 20px;
			}
			.number {
				font-size: 38px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #131313;
				line-height: 38px;
				position: relative;
				display: flex;
				align-items: center;
				margin-top: 52px;
				.icon {
					width: 26px;
					height: 26px;
					background: url(../../images/icon.png) no-repeat;
					background-size: contain;
					margin-left: 5px;
				}
			}
			.numberReduce {
				font-size: 38px;
				font-family: PingFangSC-Semibold, PingFang SC;
				font-weight: 600;
				color: #131313;
				line-height: 38px;
				position: relative;
				display: flex;
				align-items: center;
				margin-top: 30px;
				.icon {
					width: 26px;
					height: 26px;
					background: url(../../images/icon.png) no-repeat;
					background-size: contain;
					margin-left: 5px;
				}
			}
			.unitPrice {
				font-size: 28px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #6e6e6e;
				line-height: 28px;
			}
		}
		.active {
			border: 4px solid #e35450 !important;
		}
		.reduce {
			width: 100%;
			height: 64px;
			position: absolute;
			bottom: 0px;
			left: 0px;
			text-align: center;
			line-height: 64px;
			font-size: 26px;
			font-weight: 400;
			color: #6e6e6e;
		}
		.reduceActive {
			width: 100%;
			height: 64px;
			position: absolute;
			bottom: 0px;
			left: 0px;
			text-align: center;
			line-height: 64px;
			font-size: 26px;
			font-weight: 400;
			color: #ffffff;
			background: #e35450;
			border-radius: 0px 0px 15px 15px;
		}
	}
	.banner {
		margin-top: 26px;
		width: 100%;
		height: auto;
	}
	.payment {
		width: 100%;
		text-align: center;
		margin-top: 110px;
		.rechargeBtn {
			width: 420px;
			height: 90px;
			line-height: 90px;
			border-radius: 45px;
			color: #fff;
			background: linear-gradient(180deg, #e35450 0%, #df3d38 100%);
			box-shadow: 0px 10px 10px 0px rgba(227, 84, 80, 0.2);
			border-radius: 45px;
		}
	}
}
