.layout {
  display: flex !important;
  flex-flow: column;
  height: 100%;
  justify-content: space-between;
  position: relative;
  .menu-button {
    margin-right: 20px;
  }

  .title {
    font-size: 37px;
    display: block;
    text-align: center;
    flex: 1;
    padding-right: 50px;
  }
  .MuiBottomNavigationAction-root.Mui-selected {
	color: #F22B43;
  }
}
