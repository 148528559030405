.mine {
	flex: 1;
	overflow-y: scroll;
	padding: 0;
	.mine-header {
		display: flex;
		flex-flow: column;
		align-items: center;
		margin: 72px 0;
		img {
			width: 138px;
			height: 138px;
			border-radius: 100%;
			margin-bottom: 6px;
		}

		div {
			font-size: 35px;
			font-weight: bold;
			line-height: 49px;
			color: #222222;
		}
	}
	.selected {
		display: flex;
		justify-content: space-between;
		div.s-box {
			flex: 1;
			height: 184px;
			border: 1px solid #d5d5d5;
			border-radius: 10px;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: center;
			.number {
				font-size: 49px;
				font-weight: 500;
				line-height: 69px;
				color: #222222;
			}
			.tips {
				font-size: 32px;
				font-weight: 400;
				line-height: 45px;
				color: #919191;
			}
		}
		div.first {
			margin-right: 16px;
		}
	}
	.banner {
		margin-top: 26px;
		width: 100%;
		height: auto;
	}
}
