.RechargeProtocol {
	padding-top: 10px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	background: #ffffff;
	.RechargeParagraph{
		color: #000;
		margin: 16px 0;
		text-indent: 2em;
	}
	.RechargeTitle {
		font-weight: 600;
	}
}
